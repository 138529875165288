// import { Drawer, Button, Radio, Space } from "antd";
// import { useState } from "react";
// import { BsSearch } from "react-icons/bs";
// import "./search-drawer.scss";
// import { Collapse, Select } from "antd";
// export default function SearchDrawerJs({ visible2, setVisible2 }) {
//   const [placement, setPlacement] = useState("left");
//   const { Panel } = Collapse;
//   function callback(key) {
//     // console.log(key);
//   }
//   const [expandIconPosition, setExpandIconPosition] = useState("right");
//   const onPositionChange = () => {
//     setExpandIconPosition(onPositionChange);
//   };
//   const onClose = () => {
//     setVisible2(false);
//   };
//   // console.log(expandIconPosition);
//   return (
//     <>
//       <Drawer
//         placement={placement}
//         closable={false}
//         onClose={onClose}
//         visible={visible2}
//         key={placement}
//         className="SearchDrawerParent"
//       >
//         <div className="SearchDrawer">
//           <div className="Drawer">
//             <div className="SeaDrawer">
//               <div className="input">
//                 <input
//                   type="search"
//                   placeholder="Search For Products"
//                   className="inputSearchbar"
//                 />
//                 <BsSearch className="BsSearch" />
//               </div>
//               <div className="collapse">
//                 <Collapse
//                   defaultActiveKey={["1"]}
//                   onChange={callback}
//                   expandIconPosition={expandIconPosition}
//                   style={{
//                     backgroundColor: expandIconPosition
//                       ? "#dd3333"
//                       : "transparent",
//                   }}
//                 >
//                   <Panel header="Get in Touch" key="1">
//                     <div className="divDescription">
//                       <div className="divtoggle">
//                         <p>Sapphire Retail Head Office</p>
//                       </div>
//                       <div className="divtoggle">
//                         <p>1.5-Km, Defence Road, Bhobtian</p>
//                       </div>
//                       <div className="divtoggle">
//                         <p>Chowk, Off Raiwind Road, Opposite</p>
//                       </div>
//                       <div className="divtoggle">
//                         <p>University of Lahore, Lahore.</p>
//                       </div>
//                       <div className="divtoggle">
//                         <p> wecare@sapphireonline.pk</p>
//                       </div>
//                       <div className="divtoggle">
//                         <p>+92(0)42 32501005 - 6 - 7</p>
//                       </div>
//                     </div>
//                   </Panel>
//                   <Panel header="Customer Care" key="2">
//                     <div className="divDescription">
//                       <div className="divtoggle">
//                         <p>Sapphire Retail Head Office</p>
//                       </div>
//                       <div className="divtoggle">
//                         <p>1.5-Km, Defence Road, Bhobtian</p>
//                       </div>
//                       <div className="divtoggle">
//                         <p>Chowk, Off Raiwind Road, Opposite</p>
//                       </div>
//                       <div className="divtoggle">
//                         <p>University of Lahore, Lahore.</p>
//                       </div>
//                       <div className="divtoggle">
//                         <p> wecare@sapphireonline.pk</p>
//                       </div>
//                       <div className="divtoggle">
//                         <p>+92(0)42 32501005 - 6 - 7</p>
//                       </div>
//                     </div>
//                   </Panel>
//                   <Panel header="Information" key="3">
//                     <div className="divDescription">
//                       <div className="divtoggle">
//                         <p>Sapphire Retail Head Office</p>
//                       </div>
//                       <div className="divtoggle">
//                         <p>1.5-Km, Defence Road, Bhobtian</p>
//                       </div>
//                       <div className="divtoggle">
//                         <p>Chowk, Off Raiwind Road, Opposite</p>
//                       </div>
//                       <div className="divtoggle">
//                         <p>University of Lahore, Lahore.</p>
//                       </div>
//                       <div className="divtoggle">
//                         <p> wecare@sapphireonline.pk</p>
//                       </div>
//                       <div className="divtoggle">
//                         <p>+92(0)42 32501005 - 6 - 7</p>
//                       </div>
//                     </div>
//                   </Panel>
//                 </Collapse>
//               </div>
//             </div>
//           </div>
//         </div>
//       </Drawer>
//     </>
//   );
// }
import { Collapse, Drawer } from "antd";
import { useState } from "react";
import { withAppContext } from "../../Context API/App";
import "./search-drawer.scss";
function SearchDrawerJs({
  visible2,
  setVisible2,
  AllCategories,
  AllSubCategories,
  history,
}) {
  const [placement, setPlacement] = useState("left");
  const { Panel } = Collapse;
  function callback(key) {
    // console.log(key);
  }
  const [expandIconPosition, setExpandIconPosition] = useState("right");
  const onPositionChange = () => {
    setExpandIconPosition(onPositionChange);
  };
  const onClose = () => {
    setVisible2(false);
  };
  // console.log(expandIconPosition);
  return (
    <>
      <Drawer
        title={"Categories"}
        placement={placement}
        closable={false}
        onClose={onClose}
        visible={visible2}
        key={placement}
        className="SearchDrawerParent"
      >
        <div className="SearchDrawer">
          <div className="Drawer">
            <div className="SeaDrawer">
              {/* <div className="input">
                <input
                  type="search"
                  placeholder="Search For Products"
                  className="inputSearchbar"
                />
                <BsSearch className="BsSearch" />
              </div> */}
              <div className="collapse">
                <Collapse
                  defaultActiveKey={["1"]}
                  onChange={callback}
                  expandIconPosition={expandIconPosition}
                  style={{
                    backgroundColor: expandIconPosition
                      ? "#dd3333"
                      : "transparent",
                  }}
                >
                  {AllCategories?.map((e, i) => {
                    return e?.SubCategory?.length ? (
                      <Panel
                        header={e?.name}
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   history.push(`/designer-shirts?category=${e?.name}`);
                        //   onClose();
                        // }}
                        key={i + 1}
                      >
                        <div className="divDescription">
                          {e?.SubCategory?.map((a, i) => (
                            <div
                              className="divtoggle"
                              onClick={() => {
                                history.push(
                                  `/designer-shirts?category=${e?.name}&subcategory=${a?.name}`
                                );
                                onClose();
                              }}
                            >
                              <p>{a?.name}</p>
                            </div>
                          ))}
                        </div>
                      </Panel>
                    ) : (
                      <div
                        className="divtoggle"
                        onClick={() => {
                          history.push(`/designer-shirts?category=${e?.name}`);
                          onClose();
                        }}
                      >
                        <p>{e?.name}</p>
                      </div>
                    );
                  })}
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}
export default withAppContext(SearchDrawerJs);
