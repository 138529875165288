import React from "react";
import "./fullViewLoader.scss";
export default function FullViewLoader() {
  // useEffect(() => {
  //   if (window !== "undefined") {
  //     window.addEventListener("scroll", (e) => {
  //       if (e.path[1].pageYOffset > 0) {
  //         // console.log(e.path[1].pageYOffset, "pageYOffset");
  //         document.body.scrollTop = 0;
  //         document.documentElement.scrollTop = 0;
  //       } else {
  //         //   setHeaderHeight(false);
  //       }
  //     });
  //   }
  // }, []);
  return (
    // <div className="fullViewLoader" id='fullViewLoader'>
    //   <Lottie
    //     className='InnerfullViewLoader'
    //     loop
    //     animationData={shoppingbag}
    //     play
    //     // style={{ width: 150, height: 150 }}
    //   />
    // </div>
    <div className="box">
      <img src={require('../../assests/logo2.png')} width="250" className="loader_image"/>
      <span className="loader-37"></span>
    </div>
  );
}
