import React, { Suspense, useState, lazy } from "react";
import { Route } from "react-router-dom";
import FullViewLoader from "../Loader/fullViewLoader";
import Footer from "../footer/footer";
import FooterBottom from "../footer/footer-bottom";
import Navbar from "../navbar/index";
const Home = lazy(() => import("../home/Home"))
const Designer = lazy(() => import("../designer-shirts/designer"))
const ProductDetails = lazy(() => import("../product-details/product-details"))
const Account = lazy(() => import("../login/account"))
const ShoppingCart = lazy(() => import("../carts/shoppingcart"))
const CheckOut = lazy(() => import("../carts/checkout"))
const AddToWishList = lazy(() => import("../carts/addtowishlist"))
const TrackYourOrder = lazy(() => import("../login/trackyourorder"))
const OrderComplete = lazy(() => import("../carts/OrderComplete"))
const ProfileScreen = lazy(() => import("../login/profileScreen"))
const ReturnAndExchange = lazy(() => import("../policy-pages/reutrnAndExchange"))
const Wholesale = lazy(() => import("../policy-pages/wholeSale"))
function AppRouter() {
  const [search, setSearch] = useState(false);
  // useEffect(() => {
  //    let element = document.getElementById("App");
  //    document.body.scrollTop = 0;
  //    document.documentElement.scrollTop = 0;
  // }, [window.Location.pathname]);
  console.log("ROUTER");
  return (
    <Suspense fallback={<FullViewLoader />}>
      <div>
        <Navbar />
        <Route
          exact
          path={"/"}
          component={() => {
            return (
              <>
                {" "}
                <Home />
              </>
            );
          }}
        />
        {/* <Route
          exact
          path={"/trackyourorder"}
          component={() => {
            return (
              <>
                {" "}
                <TrackYourOrder />
              </>
            );
          }}
        /> */}
        <Route
          exact
          path={"/designer-shirts"}
          component={() => {
            console.log("Designer");
            return (
              <>
                <Designer />
              </>
            );
          }}
        />
        <Route
          exact
          path={"/add-to-wishlist"}
          component={() => {
            return (
              <>
                <AddToWishList />
              </>
            );
          }}
        />
        <Route
          exact
          path={"/productDetails/:id"}
          component={() => {
            return (
              <>
                <ProductDetails />
              </>
            );
          }}
        />
        <Route
          exact
          path={"/account"}
          component={() => {
            return (
              <>
                <ProfileScreen />
              </>
            );
          }}
        />
        <Route
          exact
          // path={"/account"}
          path={"/login"}
          component={() => {
            return (
              <>
                <Account type={"Login"} />
              </>
            );
          }}
        />
        <Route
          exact
          path={"/register"}
          component={() => {
            return (
              <>
                <Account type={"Register"} />
              </>
            );
          }}
        />
        <Route
          exact
          path={"/shoppingCart"}
          component={() => {
            return (
              <>
                {" "}
                <ShoppingCart />
              </>
            );
          }}
        />
        <Route
          exact
          path={"/checkout"}
          component={() => {
            return (
              <>
                <CheckOut />
              </>
            );
          }}
        />
        <Route
          exact
          path={"/OrderComplete"}
          component={() => {
            return (
              <>
                <OrderComplete />
              </>
            );
          }}
        />
        <Route
          exact
          path={"/privacy-policy/return-and-exchange"}
          component={() => {
            return (
              <>
                <ReturnAndExchange />
              </>
            );
          }}
        />
        {/* <Route
          exact
          path={"/privacy-policy/wholesale"}
          component={() => {
            return (
              <>
                <Wholesale />
              </>
            );
          }}
        /> */}
        <Footer />
        <FooterBottom />
      </div>
    </Suspense>
  );
}

export default AppRouter;
