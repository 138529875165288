import React, { lazy, Suspense, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import AppProvider from "./Context API/App";
import SaleProvider from "./Context API/Sale";
import { ToastContainer } from "react-toastify";
import FullViewLoader from "./container/Loader/fullViewLoader";
import Provider from "./Provider";
import ReactWhatsapp from "react-whatsapp";
import { RiWhatsappFill } from "react-icons/ri";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "draft-js/dist/Draft.css";
import "antd/dist/antd.css";
import "react-loading-skeleton/dist/skeleton.css";

function App() {
  const [Loading, setLoading] = useState(true);
  // useEffect(() => {
  //   // Dynamically load non-critical JavaScript
  //   const script = document.createElement('script');
  //   script.src = 'path/to/non-critical.js';
  //   script.async = true;
  //   document.body.appendChild(script);

  //   return () => {
  //     // Clean up the script when the component unmounts
  //     document.body.removeChild(script);
  //   };
  // }, []);
  useEffect(() => {
    document.fonts.ready.then(function () {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
      setTimeout(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }, 2500);
    });
  }, []);

  return (
    <div className="App" id="App">
      <ToastContainer />
      <AppProvider>
        <SaleProvider>
          <Router>
            {Loading ? <FullViewLoader Loading={Loading} /> : null}
            <ReactWhatsapp
              number="+92 303 2711152"
              message=""
              className="whatsapp_float"
            >
              <RiWhatsappFill className="whatsapp-icon" />
            </ReactWhatsapp>
            <Provider />
          </Router>
        </SaleProvider>
      </AppProvider>
    </div>
  );
}
export default App;

{
  /* </Suspense> */
}
{
  /* </Provider> */
}
