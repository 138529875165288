import React, { PureComponent } from "react";
import AppRouter from "./container/Routes/AppRouter";

class Provider extends PureComponent {
  render() {
    // console.log("EEEERRRROOORRRRRRR");
      return <AppRouter />;
  }
}

export default Provider;
