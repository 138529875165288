import axios from "axios";
import React, { createContext, useEffect, useReducer, useState } from "react";
import { BACKEND_URL } from "../../env";
import SaleReducer from "./Reducer";
// import { io } from "socket.io-client";

const initialState = {
	selected: [],
	Products: []
};

export const SaleContext = createContext();

export const withSaleContext = (Component) => (props) =>
	<SaleContext.Consumer>{(value) => <Component {...value} {...props} />}</SaleContext.Consumer>;

const SaleProvider = ({ children }) => {
	const [state, dispatch] = useReducer(SaleReducer, initialState);
	const [SpecialInstruction, setSpecialInstruction] = useState("");
	const [ProductLoading, setProductLoading] = useState(true);

	function GetAllProducts() {
		axios.get(`${BACKEND_URL}/GetAllProductsColors`).then((data) => {
			initialState.Products = data.data;
			setProductLoading(false);
		});
	}
	function GetProductInfo(id) {
		return axios.get(`${BACKEND_URL}/GetProductInfo/${id}`).then((data) => {
			return data.data;
		});
	}
	function isProductInCart(id) {
		let response = { text: "Add to Cart", Quantity: 1, inCart: false };
		state.selected.filter((a) => {
			if (a.id == id) {
				response = {
					text: "Update in Cart",
					Quantity: a.Quantity,
					inCart: true
				};
			} else {
				response = { text: "Add to Cart", Quantity: 1, inCart: false };
			}
		});
		return response;
	}
	useEffect(() => {
		// if (!initialState.Products.length) {
		// GetAllProducts();
		// }
	}, []);

	function clearSelected() {
		dispatch({
			type: "CLEAR_SELECTED"
		});
	}

	function setSelected(id, Quantity) {
		dispatch({
			type: "SET_SELECTED",
			payload: { id, Quantity }
		});
	}

	function setQuantity(id, Quantity) {
		dispatch({
			type: "SET_QUANTITY",
			payload: { id, Quantity }
		});
	}

	function removeSelected(id) {
		dispatch({
			type: "REMOVE_SELECTED",
			payload: id
		});
	}

	// const socket = io(`${BACKEND_URL}`);

	// useEffect(() => {
	// 	socket.on("connect", () => {
	// 		state.Products.map((pro, i) => {
	// 			socket.on(`Quantity-${pro.id}`, (msg) => {
	// 				pro.QuantityAvailable = msg;
	// 				state.Products.filter((a) => {
	// 					if (a.id == pro.id) {
	// 						a.QuantityAvailable = msg;
	// 					}
	// 					initialState.Products = state.Products;
	// 				});
	// 			});
	// 		});
	// 	});
	// }, [socket]);

	return (
		<SaleContext.Provider
			value={{
				selected: state.selected,
				Products: state.Products,
				ProductLoading,
				SpecialInstruction,
				setSpecialInstruction,
				GetAllProducts,
				GetProductInfo,
				isProductInCart,
				clearSelected,
				setSelected,
				setQuantity,
				removeSelected
			}}
		>
			{children}
		</SaleContext.Provider>
	);
};

export default SaleProvider;
