import React, { useState } from "react";
import { AiOutlineShopping } from "react-icons/ai";
import { FcShop } from "react-icons/fc";
import { VscAccount, VscHeart } from "react-icons/vsc";
import { useHistory } from "react-router-dom";
import DrawerOpenJs from "../navbar/drawer-open";
import "./footer-bottom.scss";
export default function FooterBottom() {
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const history = useHistory();
  const login = () => {
    history.push("/login");
  };
  // useEffect(() => {
  //   setWishList(
  //     localStorage.getItem("AddToWishList")
  //       ? JSON.parse(localStorage.getItem("AddToWishList"))
  //       : 0
  //   );
  // }, [localStorage.getItem("AddToWishList")]);
  return (
    <div className="FooterBottom">
      <div className="Bottom">
        <div
          className="BottomLogos"
          onClick={() => {
            history.push("/");
            // document.body.scrollTop = 0;
            // document.documentElement.scrollTop = 0;
          }}
        >
          <div className="Fcshop">
            <FcShop />
          </div>
          <span>Shop</span>
        </div>
        {/* <div className="BottomLogos">
          <div className="Fcshop">
            <BiFilterAlt onClick={() => setVisible2(!visible2)} />
          </div>
          <span>Filters</span>
        </div> */}
        {/* {visible2 ? ( */}
        {/* <DesignerDrawer visible={visible2} setVisible={setVisible2} /> */}
        {/* // ) : null} */}
        <div className="BottomLogos">
          <div
            className="Fcshop position"
            onClick={() => history.push("/add-to-wishlist")}
          >
            <VscHeart />
            <span className="spanchild">
              {localStorage.getItem("AddToWishList")
                ? JSON.parse(localStorage.getItem("AddToWishList")).length
                : 0}
            </span>
          </div>
          <span>WishList</span>
        </div>
        <div className="BottomLogos">
          <div className="Fcshop position">
            <AiOutlineShopping onClick={() => setVisible(!visible)} />
            <span className="spanchild">
              {localStorage?.getItem("AddToCart")
                ? JSON.parse(localStorage?.getItem("AddToCart"))?.length
                : 0}
            </span>
          </div>
          <span>Cart</span>
        </div>
        {/* {visible ? ( */}
        <DrawerOpenJs visible={visible} setVisible={setVisible} />
        {/* // ) : null} */}
        <div className="BottomLogos">
          <div className="Fcshop" onClick={login}>
            <VscAccount />
          </div>
          <span>My Account</span>
        </div>
      </div>
    </div>
  );
}
