import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import "antd/dist/antd.css";
import React, { useEffect, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { FiHeart } from "react-icons/fi";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineShoppingCart } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import { withAppContext } from "../../Context API/App";
import Logo from "../../assests/logo.png";
import DrawerOpenJs from "./drawer-open";
import "./navbar.scss";
import SearchDrawerJs from "./search-drawer";
function Navbar({
  AllCategories,
  GetAllCategories,
  SubTotalPrice,
  CalculateShipping,
  Quantity,
  AllMultipleProducts,
  ItemsQuantity,
}) {
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [CartLength, setCartLength] = useState(0);
  const [WishListLength, setWishListLength] = useState(0);
  const [HeaderHeight, setHeaderHeight] = useState(false);
  const [suitsPieces, setSuitsPieces] = useState([]);
  const [Location, setLocation] = useState(
    JSON.parse(localStorage.getItem("Location"))
      ? JSON.parse(localStorage.getItem("Location"))
      : {}
  );
  const history = useHistory();
  const login = () => {
    history.push(localStorage.getItem("CustomerToken") ? "/account" : "/login");
  };
  const push = () => {
    history.push("/");
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  useEffect(() => {
    if (window !== "undefined") {
      window.addEventListener("scroll", (e) => {
        console.log(e.path, "e.path");
        // if (e.path[1].pageYOffset > 50) {
        //   // console.log(e.path[1].pageYOffset, "pageYOffset");
        //   setHeaderHeight(true);
        // } else {
        //   setHeaderHeight(false);
        // }
      });
    }
  }, []);
  const [CategoriesL, setCategoriesL] = useState([]);
  const [CategoriesR, setCategoriesR] = useState([]);
  // useEffect(() => {
  // 	GetAllCategories();
  // }, []);

  useEffect(() => {
    const cate = [...AllCategories];
    // let cate = Categories
    let b = cate.splice(0, Math.round(cate.length / 2));
    setCategoriesL(b);
    // setCategories(cate);
  }, [AllCategories]);
  useEffect(() => {
    const cate = [...AllCategories];
    // let cate = Categories
    let b = cate.splice(
      Math.round(cate.length / 2),
      Math.round(cate.length / 2)
    );
    setCategoriesR(b);
    // setCategories(cate);
  }, [AllCategories]);
  // useEffect(() => {
  //   const scrollDemo = document.getElementsByTagName("body");
  //   console.log(scrollDemo,"scrollDemo");
  //   scrollDemo.addEventListener(
  //     "scroll",
  //     (event) => {
  //       console.log(event,"event");
  //     },
  //     { passive: true }
  //   );
  // }, []);
  const [productWeight, setProductWeight] = useState("0");
  const CalculateMultipleProductShipping = () => {
    let suits = [];
    let arr = [];
    let no = 0;
    AllMultipleProducts?.map((ev, i) => {
      no = no + Number(Number(ev?.product?.weight) * Number(ev.Quantity));
      if (!arr.includes(ev?.product?.piece)) {
        arr.push(ev?.product?.piece);
        suits.push({
          piece: ev?.product?.piece,
          quantity: ev.Quantity,
        });
      } else {
        let newFilter = suits?.filter(
          (a) => a?.piece === ev?.product?.piece
        )[0];
        let indexNew = suits.indexOf(newFilter);
        suits[indexNew].quantity = ev.Quantity + suits[indexNew].quantity;
      }
    });
    setSuitsPieces(suits);
    console.log(no, "setProductWeight");
    setProductWeight(Number(no.toFixed(0)));
  };
  useEffect(() => {
    if (AllMultipleProducts) {
      CalculateMultipleProductShipping();
    }
  }, [AllMultipleProducts]);
  return (
    <>
      <div className="navbarDiv Responsive">
        <div className="freeDelivery">
          <div className="container">
            {/* <div className="freeDel">
              <p>
                <span>Free Delivery</span> anywhere in Pakistan
              </p>
              <button
                className="trackButton"
                onClick={(e) => history.push(`/trackyourorder`)}
              >
                TRACK ORDER
              </button>
            </div> */}
            <div className="return hideTab">
              {/* <p>
                <span>30 Days</span> Easy Returns & Exchange Policy -{" "}
                <span>No Questions Asked</span>
              </p> */}
              <div className="icons">
                <span className="iconSpan">
                  <MdOutlineShoppingCart
                    className="MdOutlineShoppingCart"
                    onClick={() => setVisible(!visible)}
                  />
                  <span className="spanchild">
                    {localStorage.getItem("AddToCart")
                      ? JSON.parse(localStorage.getItem("AddToCart"))?.length
                      : 0}
                  </span>
                </span>
                {/* {visible ? ( */}
                <DrawerOpenJs visible={visible} setVisible={setVisible} />
                {/*  ) : null} */}
                <span className="iconSpan rs">
                  Rs{" "}
                  {Number(Number(SubTotalPrice).toFixed()) !== 0
                    ? Number(Number(SubTotalPrice).toFixed()) +
                      Number(
                        CalculateShipping(
                          ItemsQuantity,
                          Location,
                          suitsPieces,
                          productWeight
                        )
                      )
                    : 0}
                </span>
                <span
                  className="iconSpan"
                  onClick={() => history.push("/add-to-wishlist")}
                >
                  <FiHeart className="FiHeart" />
                  <span className="spanchild">
                    {localStorage?.getItem("AddToWishList")
                      ? JSON.parse(localStorage?.getItem("AddToWishList"))
                          ?.length
                      : 0}
                  </span>
                </span>
                <span className="iconSpan">
                  <AiOutlineUser className="FiHeart" onClick={() => login()} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`navbarDiv stickyNav ${HeaderHeight}`}>
        <div className="navbarCustom">
          <div className="navMenu container">
            <div className="drawerButton">
              <GiHamburgerMenu onClick={() => setVisible2(!visible2)} />
            </div>
            {/* {visible2 ? ( */}
            {/* <DesignerDrawer
              visible={visible2}
              setVisible={setVisible2}
              history={history}
            /> */}
            <SearchDrawerJs
              visible2={visible2}
              setVisible2={setVisible2}
              history={history}
            />
            {/* ) : null} */}
            {/* {visible2 ? (
            ) : null}{" "}
            */}
            <div
              className="navBarLinks NewCateg"
              style={
                CategoriesR?.length < 2 || CategoriesL?.length < 2
                  ? { justifyContent: "center" }
                  : { justifyContent: "space-evenly" }
              }
            >
              {/* <div className="navlink search">
								<IoIosSearch />
							</div> */}
              {CategoriesR.map((a, i) => {
                if (CategoriesL?.length > i) {
                  const menu = (
                    <Menu>
                      {a.SubCategory.map((b) => (
                        <Menu.Item
                          key={b.id}
                          onClick={(e) =>
                            history.push(
                              `/designer-shirts?category=${a?.name}&subcategory=${b?.name}`
                            )
                          }
                        >
                          {b.name}
                        </Menu.Item>
                      ))}
                    </Menu>
                  );
                  return a.SubCategory.length ? (
                    <Dropdown overlay={menu} key={a.id}>
                      <a
                        className="ant-dropdown-link navlink"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(`/designer-shirts?category=${a?.name}`);
                        }}
                      >
                        {a.name}
                        {a.SubCategory.length > 0 && <DownOutlined />}
                      </a>
                    </Dropdown>
                  ) : (
                    <a
                      className="ant-dropdown-link navlink"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`/designer-shirts?category=${a?.name}`);
                      }}
                    >
                      {a.name}
                      {/* {a.SubCategory.length > 0 && <DownOutlined />} */}
                    </a>
                  );
                }
              })}
            </div>
            <Link to={`/`}>
              <div className="logo" onClick={push}>
                <img src={Logo} />
              </div>
            </Link>
            <div
              className="navBarLinks NewCateg"
              style={
                CategoriesL?.length < 2 || CategoriesR?.length < 2
                  ? { justifyContent: "center" }
                  : { justifyContent: "space-evenly" }
              }
            >
              {CategoriesL.map((a, i) => {
                if (CategoriesR?.length > i) {
                  const menu = (
                    <Menu>
                      {a.SubCategory.map((b) => (
                        <Menu.Item
                          key={b.id}
                          onClick={(e) =>
                            history.push(
                              `/designer-shirts?category=${a?.name}&subcategory=${b?.name}`
                            )
                          }
                        >
                          {b.name}
                        </Menu.Item>
                      ))}
                    </Menu>
                  );
                  return a.SubCategory.length ? (
                    <Dropdown overlay={menu} key={a.id}>
                      <a
                        className="ant-dropdown-link navlink"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(`/designer-shirts?category=${a?.name}`);
                        }}
                      >
                        {a.name}
                        {a.SubCategory.length > 0 && <DownOutlined />}
                      </a>
                    </Dropdown>
                  ) : (
                    <a
                      className="ant-dropdown-link navlink"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`/designer-shirts?category=${a?.name}`);
                      }}
                    >
                      {a.name}
                      {a.SubCategory.length > 0 && <DownOutlined />}
                    </a>
                  );
                }
              })}
            </div>
            <div className="cartSearch">
              {/* <div className="iconDiv">
								<IoIosSearch onClick={() => setVisible2(!visible2)} />
							</div> */}
              {/* {visible2 ? (
                <DesignerDrawer visible2={visible2} setVisible2={setVisible2} />
              ) : null} */}
              <div className="iconDiv icons2">
                <span className="iconSpan" onClick={() => setVisible(!visible)}>
                  <MdOutlineShoppingCart className="MdOutlineShoppingCart" />
                  <span className="spanchild">
                    {localStorage.getItem("AddToCart")
                      ? JSON.parse(localStorage.getItem("AddToCart"))?.length
                      : 0}
                  </span>
                </span>
                {/* {visible === true ? ( */}
                <DrawerOpenJs visible={visible} setVisible={setVisible} />
                {/*  ) : null} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="navbarDiv Responsive">
        <div className="freeDelivery tractDel">
          <div className="container">
            {/* <div className="freeDel">
							<p>
								Call at <span>021-34130012</span> (Mon to Sat 10 am - 7 pm)
							</p>
						</div> */}
            <div className="freeDel text-center">
              <p>
                Whatsapp: <span>+92 303 2711152</span>
              </p>
            </div>
            {/* <div
              className="freeDel resdel"
              style={{ cursor: "pointer" }}
              onClick={(e) => history.push(`/trackyourorder`)}
            >
              <p>Track Your Order</p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default withAppContext(Navbar);
