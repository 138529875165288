import { Drawer } from "antd";
import { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import { useHistory } from "react-router-dom";
import { withAppContext } from "../../Context API/App";
import { withSaleContext } from "../../Context API/Sale";
import { BACKEND_URL } from "../../env";
import "./drawer-open.scss";

function DrawerOpenJs({
  visible,
  setVisible,
  GetAllMultipleProducts,
  GetSubTotal,
  getMultipleProductQuantity,
  AllMultipleProducts,
  Quantity,
  SubTotalPrice,
  setAllMultipleProducts,
  setQuantity,
  setSubTotalPrice,
  CalculateShipping,
  ItemsQuantity,
}) {
  const [selected, setselected] = useState([]);
  const [placement, setPlacement] = useState("right");
  const [CartData, setCartData] = useState([]);
  const [suitsPieces, setSuitsPieces] = useState([]);
  const [productWeight, setProductWeight] = useState("0");
  const [Location, setLocation] = useState(
    JSON.parse(localStorage.getItem("Location"))
      ? JSON.parse(localStorage.getItem("Location"))
      : {}
  );
  // const [SubTotalPrice, setSubTotalPrice] = useState(0);
  const history = useHistory();
  const showDrawer = () => {
    setVisible(true);
  };
  // useEffect(() => console.log(selected), [selected])
  const onClose = () => {
    setVisible(false);
  };
  const viewcart = () => {
    history.push("/shoppingCart");
    onClose();
  };
  const viewCheckOut = () => {
    history.push("/checkout");
    onClose();
  };
  useEffect(() => {
    setCartData(JSON.parse(localStorage.getItem("AddToCart")));
  }, [localStorage.getItem("AddToCart")]);
  const CalculateMultipleProductShipping = () => {
    let suits = [];
    let arr = [];
    let no = 0;
    AllMultipleProducts?.map((ev, i) => {
      no = no + Number(Number(ev?.product?.weight) * Number(ev.Quantity));
      if (!arr.includes(ev?.product?.piece)) {
        arr.push(ev?.product?.piece);
        suits.push({
          piece: ev?.product?.piece,
          quantity: ev.Quantity,
        });
      } else {
        let newFilter = suits?.filter(
          (a) => a?.piece === ev?.product?.piece
        )[0];
        let indexNew = suits.indexOf(newFilter);
        suits[indexNew].quantity = ev.Quantity + suits[indexNew].quantity;
      }
    });
    setSuitsPieces(suits);
    console.log(no, "setProductWeight");
    setProductWeight(Number(no.toFixed(0)));
  };
  useEffect(() => {
    if (AllMultipleProducts) {
      CalculateMultipleProductShipping();
    }
  }, [AllMultipleProducts]);
  // useEffect(() => {
  //   if (
  //     JSON.parse(localStorage.getItem("AddToCart")).length !==
  //       AllMultipleProducts?.length ||
  //     !AllMultipleProducts?.length
  //   ) {
  //     GetAllMultipleProducts();
  //   }
  // }, [localStorage.getItem("AddToCart")]);
  // useEffect(() => {
  //   if (
  //     JSON.parse(localStorage.getItem("AddToCart")).length ===
  //       AllMultipleProducts?.length &&
  //     Quantity.length
  //   ) {
  //     GetSubTotal();
  //   }
  // }, [CartData, AllMultipleProducts, Quantity]);
  // useEffect(() => {
  //   if (
  //     JSON.parse(localStorage.getItem("AddToCart")).length ===
  //     AllMultipleProducts?.length
  //   ) {
  //     getMultipleProductQuantity();
  //   }
  // }, [AllMultipleProducts]);
  const RemoveObj = (a, i) => {
    if (JSON.parse(localStorage.getItem("AddToCart")).length === 1) {
      localStorage.removeItem("AddToCart");
      setQuantity([]);
      setSubTotalPrice(0);
      setAllMultipleProducts([]);
    } else {
      let data = JSON.parse(localStorage.getItem("AddToCart"));
      let arr = [...data];
      arr.splice(i, 1);
      localStorage.setItem("AddToCart", JSON.stringify(arr));
      // GetAllMultipleProducts();
      // GetSubTotal();
      // getMultipleProductQuantity();
      GetAllMultipleProducts();
    }
  };
  return (
    <>
      {/* <Space>
                <div type="primary" className='button' onClick={showDrawer}>
                    <MdOutlineShoppingCart className='MdOutlineShoppingCart' />
                    <span className="spanchild">0</span>
                </div>
            </Space> */}
      <Drawer
        title="SHOPPING CART"
        placement={placement}
        closable={true}
        onClose={onClose}
        visible={visible}
        key={placement}
        className="add-to-cart-drawer"
      >
        <div className="draweropenjs">
          <div className="drawer">
            <div className="drawerCards">
              {AllMultipleProducts?.length
                ? AllMultipleProducts?.map((a, i) => {
                    // console.log((a?.Discount / 100) * a?.price, "PRICE");
                    // console.log(a?.Discount / 100, "PRICE");
                    let cost =
                      a?.Discount !== 0
                        ? (a?.Discount / 100) * a?.price
                        : a?.price;
                    let price = a?.Discount !== 0 ? a?.price - cost : a?.price;
                    return (
                      <div className="drawercard" key={a.id}>
                        <div className="divImg">
                          {/* {a?.Images?.map((e, i) => { */}
                          {/* console.log(a?.Color === e?.itemColor); */}
                          {/* return a?.color === e?.itemColor ? ( */}
                          <img
                            className="Image"
                            src={`${BACKEND_URL}/GetImage/${
                              a?.Images?.filter(
                                (item) => item?.itemColor === a?.color
                              )[0]?.filename
                            }`}
                          />
                          {/* ) : null */}
                          {/* })} */}
                        </div>
                        <div className="eliteEditiondiv">
                          <span className="lightPurple">
                            {a?.color} - {a?.product?.name} - {a?.size}
                          </span>
                          <br />
                          <span className="spanx1">{a?.Quantity} x </span>
                          <span className="spanprice">
                            ₨{Quantity[i] * price.toFixed()}
                          </span>
                        </div>
                        <div
                          className="GrCloseLogo"
                          onClick={() => RemoveObj(a, i)}
                        >
                          <GrClose className="GrClose" />
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
          <div id="Subtotalid" className="Subtotal">
            <div className="Subtotal2">
              <div className="total">
                <div className="totalpara">
                  <p className="SubtotalP">Subtotal:</p>
                </div>
                <div className="divPrice">
                  <span className="PriceSpan">
                    Rs.{" "}
                    {Number(Number(SubTotalPrice)?.toFixed(0)) !== 0
                      ? Number(Number(SubTotalPrice)?.toFixed(0)) +
                        Number(
                          CalculateShipping(
                            ItemsQuantity,
                            Location,
                            suitsPieces,
                            productWeight
                          )
                        )
                      : 0}
                    {/* {selected
												.reduce((acc, cur) => (acc += cur.Quantity * cur.price), 0)
												.toFixed(2)} */}
                  </span>
                </div>
              </div>
              <button className="VIEWCART" onClick={() => viewcart()}>
                VIEW CART
              </button>
              <button
                className="Save"
                onClick={() => AllMultipleProducts?.length && viewCheckOut()}
              >
                CHECK OUT
              </button>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default withAppContext(withSaleContext(DrawerOpenJs));
