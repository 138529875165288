import { Collapse } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import "./footerdrawer.scss";
export default function Footerdrawer() {
  const { Panel } = Collapse;
  // const { Option } = Select;

  function callback(key) {}

  const [expandIconPosition, setExpandIconPosition] = useState("right");

  const onPositionChange = () => {
    setExpandIconPosition(onPositionChange);
  };
  return (
    <>
      <div className="footer2">
        <Collapse
          className="footerDrawer"
          defaultActiveKey={["1"]}
          onChange={callback}
          expandIconPosition={expandIconPosition}
        >
          <Panel header="Get in Touch" key="1">
            <div className="divDescription">
              <p>xyz address</p>
              <p>Email: info@widerangecollections.com</p>
              <p>WhatsApp: +92 303 2711152</p>
            </div>
          </Panel>
          <Panel header="Customer Service" key="2">
            <div className="divDescription">
              <p>
                <Link
                  to="/privacy-policy/return-and-exchange"
                  style={{ color: "#FFFFFF9D" }}
                >
                  Exchanges & Returns
                </Link>
              </p>
              {/* <p><Link to="/privacy-policy/wholesale" style={{ color: "#FFFFFF9D" }}>Wholesale</Link></p> */}
            </div>
          </Panel>
          {/* <Panel header="Information" key="3">
            <div className="divDescription">
              <p>About Us</p>
              <p>Privacy Policy</p>
              <p>Store Locator</p>
              <p>Track Your Order</p>
              <p>Wholesale</p>
              <p>Fabric Glossary</p>
              <p>QisstPay Guide</p>
            </div>
          </Panel> */}
          {/* <Panel header="Newsletter Signup" key="4">
                    <div className="divDescription">
                        <p>Subscribe to our Newsletter for
                            Exclusive Updates</p>
                        <div className="newsLetterBtn">
                            <input /><button>Subscribe</button>
                        </div>
                    </div>
                </Panel> */}
        </Collapse>
      </div>
    </>
  );
}
